// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { requireLoggedIn } from '@/router/guards/require-logged-in';
import { initNavAreaMyArea } from '@/router/guards/init-nav-area';
import { requireRetailer } from '@/router/guards/require-retailer';

export const accountRoutes: RouteRecordRaw = {
    path: 'account',
    beforeEnter: [requireLoggedIn, initNavAreaMyArea],
    children: [
        {
            path: '',
            name: Navigation.Account,
            component: async () => import('./views/AccountOverviewView.vue'),
        },
        {
            path: 'settings',
            name: Navigation.AccountSettings,
            component: async () => import('./views/AccountSettingsView.vue'),
        },
        {
            path: 'users',
            name: Navigation.Users,
            component: async () => import('./views/UserListView.vue'),
        },
        {
            path: 'employees',
            name: Navigation.Employees,
            component: async () => import('./views/EmployeesView.vue'),
        },
        {
            path: 'order-history',
            name: Navigation.OrderHistory,
            component: async () => import('./views/OrderHistoryView.vue'),
        },
        {
            path: 'ctx-order-history',
            name: Navigation.CtxOrderHistory,
            component: async () => import('./views/CtxOrderHistoryView.vue'),
        },
        {
            path: 'offer-history',
            name: Navigation.OfferHistory,
            component: async () => import('./views/OfferHistoryView.vue'),
        },
        {
            path: 'invoice-history',
            name: Navigation.InvoiceHistory,
            component: async () => import('./views/InvoiceHistoryView.vue'),
        },
        {
            path: 'leads',
            name: Navigation.Leads,
            component: async () => import('./views/LeadsView.vue'),
            beforeEnter: [requireRetailer],
        },
        {
            path: 'leads/:id',
            props: true,
            name: Navigation.LeadDetails,
            component: async () => import('./views/LeadDetailView.vue'),
            beforeEnter: [requireRetailer],
        },
        {
            path: 'downloads',
            name: Navigation.Downloads,
            component: async () => import('./views/DownloadsView.vue'),
        },
    ],
};
